






import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import {addMinutes} from "date-fns";

@Component({
  computed: {
    ...mapState(['countdown_end_time']),
  },
})
export default class NotificationBarCountDownTimer extends Vue {
  timerId: number | null = null
  currentTime = new Date().getTime()
  countdown_end_time: number | null = null

  countdownMinutes: number = 1440; /// <-------- Set the countdown time here (in minutes)

  get remainingTimeInSeconds() {
    if (this.$store.state.countdown_end_time !== null) {
      return Math.max(0, (this.$store.state.countdown_end_time - this.currentTime) / 1000)
    } else {
      return 0;
    }
  }
  get hours() {
    return Math.floor(this.remainingTimeInSeconds / 3600)
  }
  get minutes() {
    return Math.floor((this.remainingTimeInSeconds % 3600) / 60)
  }
  get seconds() {
    return Math.floor(this.remainingTimeInSeconds % 60)
  }

  created() {
    if (!this.$store.state.countdown_end_time) {
      const countdownEndDate = addMinutes(new Date(), this.countdownMinutes); 
      const countdownEndTimeInMilliseconds = countdownEndDate.getTime();
      this.$store.commit('set_countdown_end_time', countdownEndTimeInMilliseconds);
    }
    this.timerId = window.setInterval(() => {
      this.currentTime = new Date().getTime(); // update current time every second
    }, 1000);
  }

 

  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.$store.commit('set_countdown_end_time', this.countdown_end_time);
  }

  @Watch('remainingTimeInSeconds')
  onRemainingTimeInSecondsChanged(value: number) {
    if (value <= 0) {
      this.$store.commit('set_show_initial_offer_notification_bar', false);
      this.$store.commit('set_countdown_end_time', null);
      this.$store.commit('set_initial_offer_code', null);
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    }
  }
    
}
