import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
//@ts-ignore
import sharedMutations from 'vuex-shared-mutations';
import {Board, BoardNotification, BoardTemplate, Role, User, Workspace} from "@/typescript/types";

Vue.use(Vuex);

class State {
  token: string | null = null;
  active_workspace_pid: string | null = null;
  active_board_pid: string | null = null;
  archive_board_id: string | null = null;
  new_board_pid: string | null = null;
  new_reminder_id: string | null = null;
  playSound: boolean = true;
  notification_objective_pid: string | null = null;
  active_workspace_access: string | null = null;
  last_seen_message: string | null = null;
  mute_notification_sound: boolean = true;
  currently_onboarding: boolean | null = false;
  currently_onboarding_in_room: boolean | null = false;
  currently_in_activation_step: boolean | null = false;
  onboarding_completed: boolean | null = false;
  onboarding_activities_added: boolean | null = false;
  onboarding_use_case: string | null = null;
  user_session_time: number | null = null;
  show_initial_offer: boolean | null = null;
  show_initial_offer_notification_bar: boolean | null = null;
  initial_offer_code: boolean | null = null;
  claim_account_prompt_count: number | null = null;

  coupon_code: string | null = null;

  user_from_ad: boolean | null = null;
  user_locked_out: boolean | null = null;
  checkout_step: number | null = null;
  first_offer_shown: boolean | null = null;



  // dashboard variables
  dashboard_view: string | null = null;
  dashboard_view_member_filter: string | null = null;
  dashboard_view_periodicity: string | null = null;
  completion_tracker_view: boolean = true;

  //objective data filter alternatives
  objective_data_members: boolean = false;
  objective_data_due_date: boolean = false;
  objective_data_start_date: boolean = false;
  objective_data_completed_activities: boolean = false;
  objective_data_status: boolean = false;
  objective_sort_type: string = 'order';

  /**
   * The objects!
   * */
  me: User | null = null;
  board: Board | null = null;
  workspace: Workspace | null = null;
  template: BoardTemplate | null = null;
  online_user_ids: Array<string> = [];
  objective_view_user_ids: Object[] = [];
  board_view_user_ids: Object[] = [];
  member_added_to_board: Object | null = null;
  member_removed_from_board: Object | null = null;

  board_notifications: BoardNotification[] = [];
  asana_token: string | null = null;
  asana_token_expired_in: Date | null = null;

  google_token: string | null = null;
  google_token_expired_in: Date | null = null;

  monday_token: string | null = null;

  totoist_token: string | null = null;

  wrike_token: string | null = null;
  wrike_token_expired_in: Date | null = null;
  dailyLoggedInStatus: string | null = null;
  newlyCreatedNortstarInBoard: string | null = null;
  isOnClaimAccountVerify: boolean | null = null;
  autoGenUserData: Object | null = null;
  isSocialLoginWithTemplate: boolean = false;
  currently_on_verification_code_step: boolean = false;
  currently_on_verification_code_step_email: boolean = false;
  openBoardMemberModal: boolean = false;
  plans: Object[] = [];
  newlyCreatedNorthstarsCountInWorkspace: number | null = null;
  totalObjectivesInTimeStamp: number | null = null;
  countdown_end_time: number | null = null;
  expansion_step: number | null = null;
  expansion_activity_id: string | null = null;
  user_onboarding_number: number | null = null;
  focus_id: string | null = null;
  in_focus: boolean = false;
  onboarding_type: string | null = null;
  onboarding_step: number | null = null;
  northStar_list_open_states: Array<string> = [];
  add_goal_open: boolean = false;
  new_objective: string | null = null;
  show_first_offer: boolean | null = null;
  display_onboarding_window: boolean = true;
  template_onboarding: boolean = false;
  dnd_onboarding: boolean = false;
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'pembio_vuex',
    }),
    sharedMutations({ predicate: ['set_asana_token', 'set_asana_token_expired','set_google_token','set_google_token_expired','set_monday_token','set_todoist_token','set_wrike_token','set_wrike_token_expired'] })
  ],

  state: new State(),

  getters: {
    isAuthenticated: state => !!state.token,
    isVerified: state => {
      if (!state.me) {
        return true
      }

      return state.me.has_verified_email
    },
    isAdminOfWorkspace: state => {
      if (!state || !state.workspace) {
        return false;
      }
      if (state.workspace.my_role === Role.PrimaryOwner) {
        return true;
      }
      if (state.workspace.my_role === Role.Owner) {
        return true;
      }

      return state.workspace.my_role === Role.Admin;
    },
    getAsanaToken: state => state.asana_token,
    getAsanaTokenExpireIn: state => state.asana_token_expired_in,
    getGoogleToken: state => state.google_token,
    getGoogleTokenExpireIn: state => state.google_token_expired_in,
    getMondayToken: state => state.monday_token,
    getTodoistToken: state => state.totoist_token,
    getWrikeToken: state => state.wrike_token,
    getWrikeTokenExpireIn: state => state.wrike_token_expired_in,
    getActiveWorkspaceAccess: state => state.active_workspace_access,
    getLastSeenMessageId: state => state.last_seen_message,
    getNewlyCreateNorthstarInBoard: state => state.newlyCreatedNortstarInBoard,
    getIsOnClaimAccountVerify: state => state.isOnClaimAccountVerify,
    getAutoGenUserData: state => state.autoGenUserData,
    getOpenBoardMemberModal: state => state.openBoardMemberModal,
    getAllPlans: state => state.plans,
    getNewlyCreatedNorthstarsCountInWorkspace: state => state.newlyCreatedNorthstarsCountInWorkspace,
    getTotalObjectivesInTimeStamp: state => state.totalObjectivesInTimeStamp,
  },

  mutations: {
    archive_board_id(state, boardId) {
      state.archive_board_id = boardId;
    },
    logged_in(state, token) {
      state.token = token;
    },
    logged_out(state) {
      state.me = null;
      state.token = null;
      state.active_board_pid = null;
      state.active_workspace_pid = null;
      state.board = null;
      state.workspace = null;
      state.isOnClaimAccountVerify = null;
      state.isSocialLoginWithTemplate = false;
      state.currently_on_verification_code_step = false;
      state.newlyCreatedNorthstarsCountInWorkspace = null;
      state.currently_onboarding = null;
      state.currently_onboarding_in_room = null;
      state.onboarding_activities_added = null;
      state.show_initial_offer = null;
      state.show_initial_offer_notification_bar = null;
      state.initial_offer_code = null;
      state.user_from_ad = null;
      state.first_offer_shown = null;
    },
    set_me(state, user) {
      state.me = user;
    },
    set_user_session_time(state, time) {
      state.user_session_time = time;
    },
    set_show_initial_offer(state, show) {
      state.show_initial_offer = show;
    },
    set_user_locked_out(state, data)  {
      state.user_locked_out = data;
    },
    set_countdown_end_time(state, time) {
      state.countdown_end_time = time;
    },
    set_expansion_step(state, step) {
      state.expansion_step = step;
    },
    set_user_onboarding_number(state, step) {
      state.user_onboarding_number = step;
    },
    set_onboarding_type(state, type) {
      state.onboarding_type = type;
    },
    set_expansion_activity_id(state, id) {
      state.expansion_activity_id = id;
    },
    set_checkout_step(state, step) {
      state.checkout_step = step;
    },
    set_active_board_pid(state, pid) {
      state.active_board_pid = pid;
    },
    set_new_board_pid(state, pid) {
      state.new_board_pid = pid;
    },
    set_active_workspace_pid(state, pid) {
      state.active_workspace_pid = pid;
    },
    set_board(state, board) {
      state.board = board;
    },
    set_template(state, template) {
      state.template = template;
    },
    set_new_reminder_id(state, id) {
      state.new_reminder_id = id;
    },
    set_workspace_boards(state, boards) {
      if (!state.workspace) {
       
        return
      }

      state.workspace.boards = boards;
    },
    set_workspace_boards_in_level_1(state, boards) {
      if (!state.workspace) {
       
        return
      }

      state.workspace.boardsinLevel1 = boards;
    },
    set_workspace_boards_in_level_2(state, boards) {
      if (!state.workspace) {
      
        return
      }

      state.workspace.boardsinLevel2 = boards;
    },
    set_workspace_boards_in_level_3(state, boards) {
      if (!state.workspace) {
        return
      }

      state.workspace.boardsinLevel3 = boards;
    },
    set_workspace(state, workspace) {
      state.workspace = workspace;
    },
    add_online_id(state, id) {
      state.online_user_ids = state.online_user_ids.filter(key => key !== id);
      state.online_user_ids.push(id);

    },
    remove_online_id(state, id) {
      state.online_user_ids = state.online_user_ids.filter(key => key !== id);
    },
    add_northStar_list_open_states(state, id) {
      state.northStar_list_open_states = state.northStar_list_open_states.filter(key => key !== id);
      state.northStar_list_open_states.push(id);

    },
    remove_northStar_list_open_states(state, id) {
      state.northStar_list_open_states = state.northStar_list_open_states.filter(key => key !== id);
    },
    add_objective_view_user_id(state, data) {
      state.objective_view_user_ids = state.objective_view_user_ids.filter((objective: any) => {
        return objective.pid == data.pid && objective.memberId !== data.memberId;
      })

      state.objective_view_user_ids.push(data);
    },
    remove_objective_view_user_id(state, data) {
      state.objective_view_user_ids = state.objective_view_user_ids.filter((objective: any) => {
        return objective.pid == data.pid && objective.memberId !== data.memberId;
      });
    },
    add_board_view_user_id(state, data) {
      state.board_view_user_ids = state.board_view_user_ids.filter((board: any) => {
        return board.pid == data.pid && board.memberId !== data.memberId;
      })

      state.board_view_user_ids.push(data);
    },
    set_member_added_to_board(state, data) {
      state.member_added_to_board=data;
    },
    set_member_removed_from_board(state, data) {
      state.member_removed_from_board=data;
    },
    remove_board_view_user_id(state, data) {
      state.board_view_user_ids = state.board_view_user_ids.filter((board: any) => {
        return board.pid == data.pid && board.memberId !== data.memberId;
      });
    },
    set_board_notification(state, data: BoardNotification[]) {
      state.board_notifications = data
    },
    add_board_notification(state, data: BoardNotification) {
      state.board_notifications = state.board_notifications.filter((notification: BoardNotification) => {
        return notification.id !== data.id
      });

      state.board_notifications.push(data);
    },
    set_asana_token(state, token){
      state.asana_token = token;
    },
    set_asana_token_expired(state, date){
      state.asana_token_expired_in = date;
    },
    set_google_token(state, token){
      state.google_token = token;
    },
    set_google_token_expired(state, date){
      state.google_token_expired_in = date;
    },
    set_monday_token(state, token){
      state.monday_token = token;
    },
    set_todoist_token(state, token){
      state.totoist_token = token;
    },
    set_wrike_token(state, token){
      state.wrike_token = token;
    },
    set_wrike_token_expired(state, date){
      state.wrike_token_expired_in = date;
    },
    set_dailyLoggedInStatus(state, data){
      state.dailyLoggedInStatus = data;
    },
    set_playSound(state, data){
      state.playSound = data;
    },
    set_notification_objective_id(state, data){
      state.notification_objective_pid = data;
    },
    set_active_workspace_access(state, access) {
      state.active_workspace_access = access;
    },
    set_last_seen_message(state, messageId) {
      state.last_seen_message = messageId;
    },
    set_newly_created_northstar_in_board(state, northstarId) {
      state.newlyCreatedNortstarInBoard = northstarId
    },
    set_mute_notification_sound(state, data){
      state.mute_notification_sound = data;
    },
    set_claim_account_verify(state, data){
      state.isOnClaimAccountVerify = data;
    },
    set_auto_gen_user_data(state, data) {
      state.autoGenUserData = data;
    },
    set_is_social_login_with_template(state, data) {
      state.isSocialLoginWithTemplate = data;
    },
    set_open_board_member_modal(state, data) {
      state.openBoardMemberModal = data;
    },
    set_currently_onboarding(state, data) {
      state.currently_onboarding = data;
    },
    set_currently_onboarding_in_room(state, data) {
      state.currently_onboarding_in_room = data;
    },
    set_currently_in_activation_step(state, data) {
      state.currently_in_activation_step = data;
    },
    set_onboarding_completed(state, data) {
      state.onboarding_completed = data;
    },
    set_onboarding_activities_added(state, data) {
      state.onboarding_activities_added = data;
    },
    set_onboarding_use_case(state, data) {
      state.onboarding_use_case = data;
    },
    set_dashboard_view(state, data) {
      state.dashboard_view = data;
    },
    set_dashboard_view_member_filter(state, data) {
      state.dashboard_view_member_filter = data;
    },
    set_dashboard_view_periodicity(state, data) {
      state.dashboard_view_periodicity = data;
    },
    set_completion_tracker_view(state, data) {
      state.completion_tracker_view = data;
    },
    set_objective_data_members(state, data) {
      state.objective_data_members = data;
    },
    set_objective_data_due_date(state, data) {
      state.objective_data_due_date = data;
    },
    set_objective_data_start_date(state, data) {
      state.objective_data_start_date = data;
    },
    set_objective_sort_type(state, data) {
      state.objective_sort_type = data;
    },
    set_objective_data_completed_activities(state, data) {
      state.objective_data_completed_activities = data;
    },
    set_objective_data_status(state, data) {
      state.objective_data_status = data;
    },
    set_currently_on_verification_code_step(state, data) {
      state.currently_on_verification_code_step = data;
    },
    set_currently_on_verification_code_step_email(state, data) {
      state.currently_on_verification_code_step_email = data;
    },
    set_all_plans(state, data) {
      state.plans = data;
    },
    set_newly_created_northstar_count(state, data) {
      state.newlyCreatedNorthstarsCountInWorkspace = data;
    },
    set_total_objectives_in_timeStamp(state, data) {
      state.totalObjectivesInTimeStamp = data;
    },
    set_user_from_ad(state, data) {
      state.user_from_ad = data;
    },
    set_coupon_code(state, code) {
      state.coupon_code = code;
    },
    set_first_offer_shown(state, data) {
      state.first_offer_shown = data;
    },
    set_focus_id(state, id) {
      state.focus_id = id;
    },
    set_in_focus(state, data) {
      state.in_focus = data;
    },
    set_add_goal_open(state, data) {
      state.add_goal_open = data;
    },
    set_new_objective(state, data) {
      state.new_objective = data;
    },
    set_show_first_offer(state, data) {
      state.show_first_offer = data;
    },
    set_claim_account_prompt(state, data) {
      if (data === null) {
        state.claim_account_prompt_count = null;
      } else {
        state.claim_account_prompt_count += data;
      }
    },
    set_display_onboarding_window(state, data) {
      state.display_onboarding_window = data;
    },
    set_template_onboarding(state, data) {
      state.template_onboarding = data;
    },
    set_dnd_onboarding(state, data) {
      state.dnd_onboarding = data;
    }
    
    

  },
  actions: {
    delete_board: ({state, commit}, boardId) => {
      const workspace = state.workspace;

      if (workspace && workspace.boards) {
        const newBoards = workspace.boards.filter((item: Board) => {
          return item.id !== boardId
        })

        const boardsInLevel1 = workspace.boardsinLevel1?.filter((item: Board) => {
          return item.id !== boardId
        })

        const boardsInLevel2 = workspace.boardsinLevel2?.filter((item: Board) => {
          return item.id !== boardId
        })

        const boardsInLevel3 = workspace.boardsinLevel3?.filter((item: Board) => {
          return item.id !== boardId
        })


        commit('set_workspace_boards_in_level_1', boardsInLevel1)
        commit('set_workspace_boards_in_level_2', boardsInLevel2)
        commit('set_workspace_boards_in_level_3', boardsInLevel3)
        commit('set_workspace_boards', newBoards)
      }
    },
  },
});
