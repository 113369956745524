













































































































































































































































































































































































































































































































































































import {Component, Mixins} from 'vue-property-decorator';
import AhaMoment from "@/components/workspace/AhaMoment.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import VerifyUser from '../VerifyUser.vue';
import UserService from '@/services/UserService';
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";

@Component({
  components: {AhaMoment, VerifyUser, ConfettiCanon},
})
export default class RoomTutorial extends Mixins(ValidationErrorParserMixin)  {
  step: number = 1;
  loading: boolean = false;
  fireConfetti: boolean = false;
  fadeCheck: boolean = false;
  fadeSpot1: boolean = false;
  fadeSpot2: boolean = false;
  fadeText: boolean = false;
  showOffer: boolean = false;

  clicked = false;
  verification: Array<string | null> = [null, null, null, null, null, null];
  startCounter: boolean = false;
  startDate: Date | null = null;
  endDate: Date | null = null;
  password: string | null = null;
  isAlternate: boolean = false;
  email: string | null = null;
  name: string | null = null;
  error: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  service: UserService | null = null;
  redirect_url: string | null = null;
  url: string | null = null;
  redirectClicked = false;

  carousels = [
    { text: "Work", id: 1, img: require('../../../assets/images/onboarding/onboarding_slide_1.jpeg')},
    { text: "Learn", id: 2, img: require('../../../assets/images/onboarding/onboarding_slide_2.jpeg') },
    { text: "Health", id: 3, img: require('../../../assets/images/onboarding/onboarding_slide_3.jpeg') },
    { text: "Money", id: 4, img: require('../../../assets/images/placeholders/empty_goals_moodboard.png') },
  ]

  confettiOrigin() {
    const options = [
      { x: 0.8, y: 0.9 },
      { x: 0.9, y: 0.9 },
      { x: 0.9, y: 0.4 },
      { x: 0.9, y: 0.3 }
    ];

    // Generate a random index to select an option
    const randomIndex = Math.floor(Math.random() * options.length);

    // Return the randomly selected option
    return options[randomIndex];
  }

  get isPasswordValid() {
    const password = this.password ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
    
  }

  get showPasswordError() {
    if(!this.isPasswordValid && this.password) {
      return true;
    } else {
      return false;
    }
  }

  get isEmailValid() {
    const email = this.email ?? '';
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }

  get isContinueButtonDisablled(){

    if(!!this.first_name && !!this.last_name && this.isPasswordValid && this.isEmailValid){
      return false;
    }
    return true;
  }

  get claimAccountButtonText() {
    if(this.isContinueButtonDisablled) {
      return 'Create account'
    } else {
      return 'Start 7 day trial'
    }
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  mounted() {
    this.$gtag.event('pmb_app_tutorial_mounted');
    this.service = new UserService(this.$apollo);
  
    setTimeout(() => {
      this.fireConfetti = true
      setTimeout(() => {
        this.fireConfetti = true
      }, 2000);
    }, 680);

    
    setTimeout(() => {
      this.fadeCheck = true;
      setTimeout(() => {
        this.fadeText = true;
          
        setTimeout(() => {
          this.fadeSpot1 = true;
          setTimeout(() => {
            this.fadeSpot2 = true;
            setTimeout(() => {
              if(!this.onGuestAccount) {
                this.closeModal();
              }
              else {
                this.step = 2
              }
            }, 1000);
          }, 100);
        }, 100);
      }, 250);
    }, 3000);
  
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  claimAccount() {
    this.clicked = true;
    this.service?.claimAccount(this.email, this.first_name, this.last_name, this.password).then(() => {
      this.$gtag.event('pmb_user_claimed_account_manually');
      this.$store.commit('set_auto_gen_user_data', null);
      this.$store.commit('set_currently_on_verification_code_step', true);
      
      //this.$store.commit('set_claim_account_verify', true);
      //@ts-ignore
      //this.$parent.close();
      // this.sendVerificationCode();
      this.handleSuccess();
      // if(this.clickedClaim) {
      //   this.openVerfy = true;
      //this.$events.fire('open-verification')
      // }

    
    },
    
    ).catch((error: any) => {
      this.parseValidationError(error);
      this.$gtag.event('pmb_user_claimed_account_failed');
    }).finally(() => {
      this.clicked = false;
    });
  }

  handleSuccess() {
    // Your logic after successful verification
    this.$gtag.event('pmb_user_claim_account_verification_success');
    this.$buefy.toast.open({
      message: 'You can now access your account',
      position: 'is-bottom-right',
      type: 'is-black',
    });
    this.$store.commit('set_claim_account_verify', false);
    this.$store.commit('set_currently_on_verification_code_step', false);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.clicked = false;
    this.$emit('account-claimed', true)
    location.reload();

  
  }

  socialAuth(network: string) {
    if(network) {
      this.$gtag.event('pmb_app_decision_social_' + network);
    }
    this.redirect_url = window.location.origin;
    switch (network) {
      case 'google':
        this.redirect_url = this.redirect_url + '/authcallback/google';
        this.url = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=965126139567-mq7r25ei3o7ima0rostc0h28e5lvhnqi.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=' + this.redirect_url;
        break;
      case 'facebook':
        this.redirect_url = this.redirect_url + '/authcallback/facebook';
        this.url = 'https://www.facebook.com/v6.0/dialog/oauth?client_id=485296225720771&redirect_uri=' + this.redirect_url + '&state=&scope=email';
        break;
      case 'linkedin':
        this.redirect_url = this.redirect_url + '/authcallback/linkedin';
        this.url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86a1mb9j6txwi0&redirect_uri=' + this.redirect_url + '&state=&scope=r_liteprofile%20r_emailaddress';
        break;
      default:
        this.url = '/';
    }
    window.location.href = this.url;

  }

  handleShowOffer() {
    this.showOffer = true;
  }

  handleClaimOffer() {
    this.$router.push({name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }

  handleNo() {
    this.step = 3 
  }

  inviteMember() {
    this.$gtag.event('pmb_app_room_tutorial_member_invite_accepted');
    //@ts-ignore
    this.$parent.close();
    this.openBoardMembers();
    this.routeToBoard();
  }

  skipMemberInvite() {
    this.$gtag.event('pmb_app_room_tutorial_member_invite_skip');
    this.routeToBoard();
  }

  completeTutorial() {
    this.$gtag.event('pmb_app_room_tutorial_challenge_accepted');
    this.routeToBoard();
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        onboarding: true
      },
      canCancel: false,
    });
  }

  nextStep() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.step++;
    }, 600);
  }

  routeToBoard() {
    this.$gtag.event('pmb_app_tutorial_completed');
    //@ts-ignore
    this.$parent.close();
  }
}


